import React, { Component } from "react";
import { variables } from '../Shared/Variables';
import { Navigate, useNavigate } from "react-router-dom";
import CourseCard from "../Shared/CourseCard";
import moment from "moment";
import SkeletonCard from "../Shared/SkeletonCard";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TrainerMessage from "../CourseIndex/TrainerMessage";
import LanguagePopup from "../Shared/LanguagePopup";
import { error } from "jquery";
import ViewTutorialPopUp from "../CourseIndex/viewContentPopUp";
import Cookies from "universal-cookie"
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import axios from "axios";
import Translate from '../Shared/Translate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { callingDispose, updateFilterRetained, updateFilterVal, updateNodeVal, updateIsUserLoggedIn, updateAccordianNo, updateOnlineLec, updateGuidedLearning, updateActiveStage1List, updateActiveStage4List } from "../../features/CourseIndex/courseIndexSlice";
import {
    updateChild,
    updateParent,
    updategrandChild,
    updateggChild,
    updateExtended,
} from "../../features/breadcrumb/breadcrumbSlice";
import { updateIsActiveLi } from "../../features/navbar/navbarSlice"
import CustumLoader from "../Shared/CustumLoader";
import dateFormat from "dateformat";
export class courselist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mycourses: [],
            showCourse: false,
            showForm: false,
            egaScore: false,
            goProfile: false,
            isLoader: false,
            activeStage: null,
            isUnauthorizedUser: false,
            isDownloadCertificate: false,
            isBtnDisabled: "",
            isUserNew: "",
            setOK: "",
            openlanguage: false,
            openVideo: false,
            selectedCourse: null, // Added to track selected course for video

        }
        this.viewCourse = this.viewCourse.bind(this);
        this.downloadCandidateCertificate = this.downloadCandidateCertificate.bind(this);
        this.isBtnDisabled = this.isBtnDisabled.bind(this);
        this.ApiCallingForAuto = this.ApiCallingForAuto.bind(this);
        this.closeVideo = this.closeVideo.bind(this); // Bind closeVideo method
        this.updateCourseAccessStatus = this.UpdateCourseAccessStatus.bind(this);

    }

    logout = async () => {

        // console.log("props: " + JSON.stringify(this.props))
        const cookies = new Cookies();
        this.props.callingDispose(true);
        this.props.updateFilterRetained(true);
        this.props.updateFilterVal("");
        this.props.updateNodeVal([]);
        this.props.updateOnlineLec([]);
        this.props.updateIsUserLoggedIn(false);
        this.props.updateAccordianNo(0);
        this.props.updateggChild("");

        try {
            let url = variables.API_URL + 'api/UpdateUserLogoutDetails'
            const data = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SessionId: cookies.get('sessionId'),
                    UserId: sessionStorage.getItem('login-userId')
                })
            })

            const res = await data.json();
            const logoutUser = res.isSuccess

            if (logoutUser) {
                sessionStorage.clear();
                localStorage.clear();
                cookies.remove('language');
                cookies.remove('default-language');
                cookies.remove('sessionId');
                // Redirect to '/'
                //alert('network error!!!  logged out')

                window.location.href = '/'
            } else {
                // console.log('logout unsuccessful')
            }
        } catch (error) {
            // console.log(error)
        }
    }


    async functionForEga(data) {
        // console.log("functionForEga calling : ", data);
        const response = await axios.post('https://edmyst.auth.us-east-1.amazoncognito.com/oauth2/token', {
            grant_type: 'client_credentials',
            scope: 'tokens/access',
            client_id: '5ra5eln38nlf99lvj7m7lr7dhb',
            client_secret: '1r0s8ptui3fjq61joh9tdp3heigbbb6d4ojdamtv82v38qjpapm2'
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
                'Cookie': 'XSRF-TOKEN=742b630f-805c-4ec8-bad2-0f7da9bec88b'
            }
        });
        // // console.log('Response:', response);
        if (response.data.access_token != "") {
            sessionStorage.setItem("accessToken", response.data.access_token);
        }
        this.setState({ isLoader: false });
    }

    getMyCourse() {
        var logindata = sessionStorage.getItem("login-userId");
        const courseIdRegisterFor = sessionStorage.getItem("courseIdRegisterFor")
        var userId = logindata;
        this.setState({ isLoader: true });
        const encodedValue = encodeURIComponent(userId);
        fetch(variables.API_URL + 'api/MyCourses/' + encodedValue + '/' + variables.TimeZone,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")
                },
            })
            .then(response => {
                if (response.status === 404 || response.status === 401) {
                    // logout
                    // console.log("response: " + JSON.stringify(response))

                    this.logout()
                } else if (response.statusText == "Unauthorized") {
                    this.setState({ isUnauthorizedUser: true });
                } else
                    return response.json();
            })
            .then(data => {
                // console.log("data in certificate : ", data);
                if (data.isSuccess) {
                    if (data.dataList.length > 0) {
                        var dl = data.dataList;
                        var batchIdsList = []
                        var activeStage1List = []
                        var activeStage4List = []

                        for (var i = 0; i < dl.length; i++) {
                            // // console.log("dl.batchId : ",dl[i].batchId);
                            if (dl[i].vcStartDate != null) {
                                var duration = dl[i].vcDuration;
                                var currentDt = moment().format("DD MMM YYYY | hh:mm A");
                                dl[i].vcStartDate = moment(dl[i].vcStartDate).format("DD MMM YYYY | hh:mm A");

                                if (moment(dl[i].vcStartDate, "DD MMM YYYY | hh:mm A").add("minutes", duration).diff(moment(currentDt, "DD MMM YYYY | hh:mm A"), "minutes") <= 0)
                                    dl[i].vcStartDate = null;
                            }
                            if (dl[i].activeStage == '1') {
                                activeStage1List.push(dl[i]);
                            } else {
                                activeStage4List.push(dl[i]);
                            }
                            batchIdsList.push(dl[i].batchId);
                            if (courseIdRegisterFor) {
                                if (courseIdRegisterFor == dl[i].courseId) {
                                    if (dl[i].activeStage == "1") {
                                        this.props.updateIsActiveLi("Assessment");
                                    } else {
                                        this.props.updateIsActiveLi("courses");
                                    }
                                }
                            } else {
                                if (activeStage1List.length > 0) {
                                    // alert("updateIsActiveLi  Assessment")
                                    this.props.updateIsActiveLi("Assessment");
                                } else {
                                    this.props.updateIsActiveLi("courses");
                                }
                            }

                            if (dl[i].activeStage == '1' && dl[i].externalExam != false) {
                                this.functionForEga(dl);
                            }
                            // console.log("batchIdsList : ", batchIdsList);
                            // console.log("activeStage4List : ",activeStage4List);
                            // console.log("activeStage1List : ",activeStage1List);
                        }
                        this.props.updateActiveStage1List(activeStage1List);
                        this.props.updateActiveStage4List(activeStage4List);
                        // console.log("dl for allCertificateData : ", dl)
                        var allCertificateData = dl.filter((it) => { return it.courseCompleted == 1 || it.markedCompleted && it.certificate != "" })

                        // console.log("allCertificateData for profile content : ", allCertificateData);
                        sessionStorage.setItem("allCertificateData", JSON.stringify(allCertificateData));
                        sessionStorage.setItem("batchIdsList", batchIdsList);

                    }
                    this.setState({ isLoader: false });

                    this.setState({ mycourses: data.dataList });
                    this.checkUserNew();

                } else {
                    this.setState({ isLoader: false });
                }
            }).catch((error) => {
                // console.log("error in api/MyCourses/ : ", error);
                this.setState({ isLoader: false });
            });
    }


    UpdateCourseAccessStatus = async (data) => {
        var userId = sessionStorage.getItem("login-userId");
        // console.log("UpdateCourseAccessStatus calling : ", data);
        const body = {
            "UserID": userId,
            "CourseId": data.courseId,
            "ProjectID": data.projectId,
            "BatchID": data.batchId
        }
        axios.post(variables.API_URL + 'api/UpdateCourseAccessStatus', body).then((res) => {
            // console.log("response from api UpdateCourseAccessStatus : ", res);
        }).catch((err) => {
            // console.log("error from api UpdateCourseAccessStatus : ", err);
        });
    }


    async saveExternalLinkData(data, url, resultId) {
        var userId = sessionStorage.getItem("login-userId");
        const body = {
            "userId": userId,
            "courseId": data.courseId,
            "projectId": data.projectId,
            "batchId": data.batchId,
            "externalAssessmentlink": url,
            "externalResultAssessmentId": resultId
        }
        axios.post(variables.API_URL + 'api/SaveExternalAssessmentlink', body).then((res) => {
            // console.log("response from api SaveExternalAssessmentlink : ", res);
            if (res.data.isSuccess) {
                window.location.href = url;
                this.setState({ isLoader: false });
            } else {
                // alert("error in SaveExternalAssessmentlink api");
                confirmAlert({
                    message: "Something went wrong, try again",
                    buttons: [
                        {
                            label: "Try Again",
                            onClick: () => {
                                this.cancelBtnClicked();
                            },
                            class: "primary-btn",
                        },
                    ],
                    closeOnClickOutside: false,
                })
            }
        }).catch((err) => {
            // console.log("error from api SaveExternalAssessmentlink : ", err);
        });
    }


    async addUser(data) {
        var obj = {
            "UserID": sessionStorage.getItem("login-userId"),
            "CourseId": data.courseId,
            "ProjectID": data.projectId,
            "BatchID": data.batchId
        }
        this.setState({ isLoader: true });
        var userId = sessionStorage.getItem("login-userId");
        // console.log("addUser calling for external exam : ", data);
        const AUTH_TOKEN = sessionStorage.getItem("accessToken");

        const response = await axios.post(`https://rz16px4j4i.execute-api.us-east-1.amazonaws.com/Prod/create/add_candidates?assessment_id=${data.externalAssessmentId}`, {


            candidates: [
                {
                    email: data.email == "" ? "testing25@gmail.com" : data.email,
                    name: data.firstName,
                    key1: obj,

                }
            ]
        }, {
            headers: {
                'Authorization': AUTH_TOKEN,
                'Content-Type': 'application/json'
            }
        });
        // console.log('Response after add user :', response);
        if (response.data.status == "success" /* &&  */) {

            if (response.data.message == "Candidates added successfully") {
                var newAppUrl = response.data.added_candidates[0]?.assessment_url;
                var resultId = response.data.added_candidates[1].assessment_results_id[0]
                sessionStorage.setItem("assesmentResultId", response.data.added_candidates[1].assessment_results_id[0]);
                this.saveExternalLinkData(data, newAppUrl, resultId);
            } else {
                if (response.data.existing_candidates[0].key1 != "EXTRA_KEYS_FOR_REFERENCE") {
                    if (userId === response.data.existing_candidates[0].key1.UserID) {
                        if (response.data.existing_candidates[0]?.assessment_status === "Pending" || response.data.existing_candidates[0]?.assessment_status === "In Progress" || response.data.existing_candidates[0]?.assessment_status === "Abandoned") {
                            //debugger;
                            window.location.href = response.data.existing_candidates[0].assessment_url;
                        } else {
                            const coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
                            this.setState({ egaScore: true });
                            this.props.updateChild(coursedata.courseName)
                            console.log("response.data : ", response.data);
                            this.setState({ assessment_result_id: response.data.existing_candidates[0].assessment_result_id })
                            sessionStorage.setItem("assesmentResultId", response.data.existing_candidates[0].assessment_result_id);
                            sessionStorage.setItem("assesmentStatus", response.data.existing_candidates[0].assessment_status);

                        }
                    } else {
                        this.setState({ isLoader: false });

                        // console.log("going to show confirmation elert for email updation");
                        confirmAlert({
                            message: this.state.setOK,
                            buttons: [
                                {
                                    label: "Change email",
                                    onClick: () => this.goToProfile(),
                                    class: "primary-btn"
                                },
                                {
                                    label: "Cancel",
                                    onClick: () => this.cancelBtnClicked(),
                                    class: "secondary-btn"
                                }
                            ]
                        })
                    }
                } else if (response.data.existing_candidates[0].key1 == "EXTRA_KEYS_FOR_REFERENCE" && response.data.existing_candidates[0].assessment_status == "Completed") {
                    const coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
                    this.setState({ egaScore: true });
                    this.props.updateChild(coursedata.courseName)
                    this.setState({ assessment_result_id: response.data.existing_candidates[0].assessment_result_id })
                    sessionStorage.setItem("assesmentResultId", response.data.existing_candidates[0].assessment_result_id);
                    sessionStorage.setItem("assesmentStatus", response.data.existing_candidates[0].assessment_status);

                }
            }
        } else {
            // console.log("api of add user got an issue");
            this.setState({ isLoader: false });
        }
    }

    /*  async goToAssessment() {
         this.setState({ egaScore: true });
          fetch('https://api.allorigins.win/get?url=https://www.edmyst.coach/Step1/5ra5eln38nlf99lvj7m7lr7dhb/efcbc3c5-b51d-486f-b030-f09560ad2e74').then(response => {
             if (!response.ok) {
                 throw new Error('Network response was not ok ' + response.statusText);
             }
             return response.json();
         })
             .then(data => {
                 // console.log(data.contents)
             })
             .catch(error => {
                 console.error('There was a problem with the fetch operation:', error)
             }) 
         // alert("need to call api and check if user submiited assessment or not")
         // const res = await axios.get(`${data.externalAssessmentlink}`);
         // // console.log("response from assessment link api : ",res);
     } */


    cancelBtnClicked() {
        // console.log("cancelBtnClicked calling");
    }


    viewCourse = async (data) => {
        // sessionStorage.setItem("coursedata", null);
        console.log("data in view course : ", data.proficiencyTestEndDate)
        sessionStorage.setItem("isCourseStarted", data?.isCourseStarted)

        sessionStorage.setItem("guidedLearningStatus", data?.guidedLearningStatus)
        sessionStorage.setItem("coursedata", JSON.stringify(data));
        sessionStorage.setItem("certificateData", JSON.stringify(data));
        await this.props.updateNodeVal([]);
        if (data.isFormDetailsMandate == true && data.isCandidateFormSubmitted == false) {
            this.setState({ showForm: true });
        } else if (data.activeStage == 1) {
            this.setState({ activeStage: data.activeStage });
            // alert("external test add user api call");

            if (data.proficiencyTestEndDate != "" && data.proficiencyTestEndDate != undefined && data.markedCompleted == "0") {
                // alert("inside date test")
                const endDate = new Date(data.proficiencyTestEndDate);
                // Get the current date and time
                const currentDate = new Date();
                console.log("endDate : ", endDate);
                endDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);
                console.log("currentDate : ", currentDate);
                // Compare the two dates
                if (endDate >= currentDate) {
                    if (data.externalExam === true) {
                        if (data.markedCompleted != "1") {
                            /*  confirmAlert({
                                 message: "Confirming",
                                 buttons: [
                                     {
                                         label: "OK",
                                         onClick: () => this.addUser(data),
                                         class: "primary-btn"
                                     },
                                     {
                                         label: "Cancel",
                                         onClick: () => this.cancelBtnClicked(),
                                         class: "secondary-btn"
                                     }
                                 ]
                             });
                         } else { */
                             this.setState({isLoader:true})
                            this.addUser(data)
                        } else {

                            const coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
                            sessionStorage.setItem("assesmentResultId", data.externalResultAssessmentId);

                            this.setState({ egaScore: true });
                            this.props.updateChild(coursedata.courseName)
                            this.setState({ assessment_result_id: data.externalResultAssessmentId })
                        }
                    }
                    else {
                        this.setState({ showCourse: true });
                    }
                } else {
                    confirmAlert({
                        message: `The test window is closed. The last date to complete the test was ${dateFormat(data.proficiencyTestEndDate, "dd mmm yyyy")}.`,
                        buttons: [
                            {
                                label: "OK",
                                onClick: () => {
                                    console.log("on click of confirmation alert is clicled");
                                },
                                class: "primary-btn",
                            },
                        ],
                        closeOnClickOutside: false,
                    });
                }
            } else {
                if (data.externalExam === true) {
                    if (data.markedCompleted != "1") {
                        /*  confirmAlert({
                             message: "Confirming",
                             buttons: [
                                 {
                                     label: "OK",
                                     onClick: () => this.addUser(data),
                                     class: "primary-btn"
                                 },
                                 {
                                     label: "Cancel",
                                     onClick: () => this.cancelBtnClicked(),
                                     class: "secondary-btn"
                                 }
                             ]
                         });
                     } else { */
                     this.setState({isLoader:true})

                        this.addUser(data)
                    } else {

                        const coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
                        sessionStorage.setItem("assesmentResultId", data.externalResultAssessmentId);

                        this.setState({ egaScore: true });
                        this.props.updateChild(coursedata.courseName)
                        this.setState({ assessment_result_id: data.externalResultAssessmentId })
                    }
                }
                else {
                    this.setState({ showCourse: true });
                }
            }
        }
        else {
            sessionStorage.setItem("courseIdRegisterFor", data.CourseId);
            if (data.isCourseStarted === "False" && data.isCandidateFormSubmitted == true) {
                this.setState({ openVideo: true });
                this.setState({ selectedCourse: data });
                this.UpdateCourseAccessStatus(data)
            } else {
                this.setState({ showCourse: true });
                this.UpdateCourseAccessStatus(data)
                this.setState({ activeStage: data.activeStage });
                this.ApiCallingForAuto(data)
            }
        }
    }
    ApiCallingForAuto = (data) => {
        // console.log("Api calling for auto certificate generation : ", data);
        var userId = sessionStorage.getItem("login-userId");
        if (data.isCourseStarted == "True" && data.markedCompleted == 1 && data.generateCertificate == "Auto") {
            // alert("calling api");
            const body = {
                "userId": userId,
                "courseId": data.courseId,
                "projectId": data.projectId,
                "batchId": data.batchId
            }
            axios.post(variables.API_URL + 'api/GenearateCertificate_v1', body).then((res) => {
                // console.log("response from api ApiCallingForAuto : ", res);
            }).catch((err) => {
                // console.log("error from api ApiCallingForAuto : ", err);
            });
        } else {
            // console.log("no need to call api");
        }
    }



    downloadCandidateCertificate = (courseName, certificateFileName, data) => {

        // console.log("certificateFileName initial : ", certificateFileName);
        // console.log("courseName initial : ", courseName);
        // console.log("data initial : ", data);
        if (data.restrictCandidateAccess) {
            var certificateImgFileName = ((certificateFileName != "") && (certificateFileName != null) && (certificateFileName != undefined)) ? variables.CertificateFolderPath + "/" + certificateFileName.replace("pdf", "png") : variables.CertificateDefaultImg;
            certificateFileName = ((certificateFileName != "") && (certificateFileName != null) && (certificateFileName != undefined)) ? variables.CertificateFolderPath + "/" + certificateFileName : "";

            // console.log("certificateImgFileName after : ", certificateImgFileName);
            sessionStorage.setItem("coursename", null);
            sessionStorage.setItem("coursename", courseName);
            sessionStorage.setItem("certificateimgfilename", null);
            sessionStorage.setItem("certificateimgfilename", certificateImgFileName);
            sessionStorage.setItem("certificatepdffilename", null);
            sessionStorage.setItem("certificatepdffilename", certificateFileName);
            sessionStorage.setItem("certificateData", JSON.stringify(data));
            sessionStorage.setItem("certificateFrom", "mycourse");
            this.setState({ isDownloadCertificate: true });
        }
    }

    isBtnDisabled = (type, courseData) => {
        var isDisable = "";

        if (type == "enrolled") {
            isDisable = ((courseData.activeStage === 1) && (courseData.batchId === 0) && (courseData.egaPendingCnt === 0)) ? "disabled" : "";
        }

        this.setState({ isBtnDisabled: isDisable });
    }
    checkUserNew() {
        const cookies = new Cookies();
        var lang = cookies.get("language");
        // console.log("lang for user : ", lang);
        if (lang == '') {
            // console.log("its a new user go for lang and video : ", this.state.openlanguage);
            if (this.state.openlanguage == false && this.state.isUserNew == false) {
                this.setState({ isUserNew: true });
                this.setState({ openlanguage: true });
            }
        } else {
            console.log("user is old");
        }
    }

    closeVideo = async () => {
        this.setState({ openVideo: false });
        const data = await this.state.selectedCourse;
        if (data.isFormDetailsMandate === true && data.isCandidateFormSubmitted === false) {
            this.setState({ showForm: true });
        } else {
            this.setState({ showCourse: true });
        }
        this.setState({ activeStage: data.activeStage });
        this.ApiCallingForAuto(data);
    }

    okLanguage = () => {
        // console.log("working here in okLanguage func")
        this.setState({ openlanguage: false });
        this.setState({ openVideo: true });
        // setOpenVideo(true);
        // setOpenLanguage(false);
    }

    goToProfile = () => {
        sessionStorage.setItem("comingfromeo", true)
        this.setState({ goProfile: true });

    }
    componentDidMount() {
        this.getMyCourse();

        // this.closeVideo();
        // this.okLanguage()
    }
    render() {
        const { mycourses } = this.state;
        const { showCourse } = this.state;
        const { showForm } = this.state;
        const { egaScore } = this.state;
        const { goProfile } = this.state;

        const { assessment_result_id } = this.state;
        const { activeStage } = this.state;
        const { isUnauthorizedUser } = this.state;
        const { isDownloadCertificate } = this.state;
        const { isLoader } = this.state;
        const { openlanguage } = this.state;
        const { isUserNew } = this.state;
        const { openVideo } = this.state;
        const { isDeviceFromRedux } = this.props;
        const { isActiveLi } = this.props;
        const getOK = (ok) => {
            if (ok !== this.state.setOK) {
                this.setState({ setOK: ok });
            }
            // console.log({ ok })
        };
        // console.log("openLanguage in courseList : ", openlanguage);

        // console.log("mycourses : ", mycourses)
        const enrolledCourses = mycourses.filter((course) => {
            // if (course.externalExam) {
            //     return true; // Include in enrolledCourses if externalExam is true
            // }
            return (course.courseCompleted == 0 && course.batchCompleted == 0 && course.markedCompleted == 0) && course.activeStage == "4";
        });

        // console.log("enrolledCourses : ",enrolledCourses);

        const complteCourses = mycourses.filter((course) => {
            if (course.externalExam) {
                return false; // Exclude from complteCourses if externalExam is true
            }
            return (course.courseCompleted == 1 || course.batchCompleted == 1 || course.markedCompleted == 1) && course.activeStage == "4";
        });

        const enrolledAsessments = mycourses.filter((course) => {
            if (course.externalExam) {
                return true; // Include in enrolledCourses if externalExam is true
            }
            return (course.courseCompleted == 0 && course.batchCompleted == 0 && course.markedCompleted == 0) && course.activeStage == "1";
        });

        const complteAsessments = mycourses.filter((course) => {
            if (course.externalExam) {
                return false; // Exclude from complteCourses if externalExam is true
            }
            return (course.courseCompleted == 1 || course.batchCompleted == 1 || course.markedCompleted == 1) && course.activeStage == "1";
        });

        if (showCourse) {
            // console.log("isDeviceFromRedux : ", isDeviceFromRedux);
            if (isDeviceFromRedux == "mobile") {
                return <Navigate to={`/${activeStage === 1 ? 'eligibility' : 'courseIndexMiddlePage'}`} />;
            } else {
                return <Navigate to={`/${activeStage === 1 ? 'eligibility' : 'CourseIndex'}`} />;
            }
        }
        if (egaScore) {
            this.props.updateParent("My course");
            //this.props.updateChild("Ega score")
            return <Navigate to={`/EgaScore/${assessment_result_id}`} />

        }
        if (goProfile) {
            return <Navigate to="/UserProfile" />
        }

        if (showForm) {
            return <Navigate to={`/${activeStage === 1 ? 'WelcomeMenu' : 'WelcomeMenu'}`} />;
        }
        else if (isUnauthorizedUser) {
            return <Navigate to="/" />;
        }
        else if (isDownloadCertificate) {
            return <Navigate to="/DownloadCertificate" />
        }
        else {

            // console.log("enrolledCourses : ", enrolledCourses);

            return (

                <div className="container-fluid bg-default">
                    {/*  <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoader}

                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> */}
                    {isLoader && <CustumLoader message="coursecontent006" />}

                    {
                        openlanguage && <LanguagePopup
                            show={openlanguage}
                            closeEvent={this.closeVideo}
                            okEvent={this.okLanguage}
                            isUserNew={true}
                            type="center"
                            headerKey="registration002"
                        />
                    }
                    {
                        openVideo ? (
                            <>
                                {/* <div class="row m-2">
                                <div class="popup-one text-lg-left" oncontextmenu="return false;" >
                                  <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="mb-2 float-end close-icon"
                                    onClick={closeVideo}
                                  ></img>
                                  <div class="mb-3">
                                    <h6 class="p-0 m-0">
                                      <Translate textKey="registration001" />
                                    </h6>
                                  </div> 
                                  <VideoPlayer src={defsrc} />
                                </div>
                              </div> */}
                                <ViewTutorialPopUp show={openVideo} onHide={this.closeVideo} title="mycourse001" src={`https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/MyCourse_${(sessionStorage.getItem("prefferedLanguage") === "Hindi" || sessionStorage.getItem("prefferedLanguage") === "HI")

                                    ? "hin"

                                    : "eng"

                                    }.mp4`} showHeading={true} />
                                <section class="modal overlay"></section>
                            </>
                        ) : (
                            <></>
                        )
                    }
                    <div class="container pt-3 p-2">
                        {/* <TrainerMessage pageName='MyCourse' text="Before you begin, watch the video on a tour of the LMS" title="mycourse001" /> */}
                        {/* How to use LMS */}
                    </div>
                    <div style={{ display: 'none' }}>
                        <Translate textKey="eligibility002" setvalue={getOK} />


                    </div>
                    {
                        isActiveLi == "courses" ?
                            <>
                                <div class="container pb-7">
                                    {
                                        mycourses.length > 0 && mycourses != null && mycourses != undefined ?
                                            <>
                                                {enrolledCourses.length > 0 && (
                                                    <div>
                                                        <div className="row my-course-heading">

                                                            <h2><Translate textKey="mycourse002" /></h2>
                                                            <div className="col-lg-12">
                                                                <p class="m-0">
                                                                    Continue my education in a vocational course that allows me to gain hands-on experience and further develop my skills in a specific field area.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row pt-3">
                                                            {enrolledCourses.map(course =>
                                                                <div class="col-lg-3 col-sm-12 col-xs-12 mb-3 ps-2 pe-2">
                                                                    <CourseCard courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
                                                                        courseName={course.courseName}
                                                                        duration={course.courseDuration}
                                                                        activeStage={course.activeStage}
                                                                        description={course.description}
                                                                        buttonClick={() => this.viewCourse(course)}
                                                                        sectorName={course.sectorName}
                                                                        buttonValue={course.isFormDetailsMandate == true && course.isCandidateFormSubmitted == false ? "mycourse008" : course.activeStage == 1 ? "TAKE TEST" : course.isCourseStarted == "False" ? "mycourse005" : course.isCourseStarted == "True" ? "mycourse006" : "VIEW COURSE"}
                                                                        type={(course.markedCompleted == "1" && course.activeStage === 1) ? "completed" : "enrolled"}
                                                                        externalExam={course.externalExam}
                                                                        egaPendingCnt={course.egaPendingCnt}
                                                                        batchID={course.batchId}
                                                                        batchStartDate={course.batchStartDate}
                                                                        batchEndDate={course.batchEndDate}
                                                                        vcStartTime={course.vcStartDate}
                                                                        vcDuration={course.vcDuration}
                                                                        isDisabled={() => this.isBtnDisabled('enrolled', course)}
                                                                        isBatch={course.activeStage == 4 && course.batchId === 0 ? false : true}
                                                                        isCandidateFormSubmitted={course.isCandidateFormSubmitted}
                                                                        isFormDetailsMandate={course.isFormDetailsMandate}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {complteCourses.length > 0 && (
                                                    <div class="mt-3">
                                                        <div className="row my-course-heading">
                                                            {/*<h1>Completed<small className="text-muted-one">Courses</small></h1>*/}
                                                            <h2>Completed Courses</h2>
                                                            <div class="col-lg-12">
                                                                <p>
                                                                    Secure my career advancement by demonstrating my newly acquired knowlegde and expertise in my chosen professsion
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="container pb-7 p-0">
                                                            <div className="row completed-courses mb-5 ">
                                                                {complteCourses.map((course) => {
                                                                    // console.log("course Item : ", course);
                                                                    return (
                                                                        <>
                                                                            <div className="col-lg-3 col-sm-12 col-xs-12 mb-3 ps-2 pe-2">
                                                                                <CourseCard courseData={course} courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
                                                                                    courseName={course.courseName} sectorName={course.sectorName} type="completed"
                                                                                    courseCompletedOn={course.courseCompletedOn}
                                                                                    activeStage={course.activeStage}
                                                                                    certificationDate={course.certificationDate}
                                                                                    isCertificateToShow={course.isCertificateToShow}
                                                                                    deliveryMode={course.deliveryMode} examMode={course.examMode} markForGrading={course.markForGrading} isPassed={course.isPassed}
                                                                                    certificate={course.certificate} buttonClick={!course.restrictCandidateAccess ? () => this.viewCourse(course) : () => this.downloadCandidateCertificate(course.courseName, course.certificate, course)}
                                                                                    restrictCandidateAccess={course.restrictCandidateAccess} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </> :
                                            <>
                                                <SkeletonCard />
                                            </>
                                    }
                                </div>
                            </> :
                            <>
                                <div class="container pb-7">
                                    {
                                        mycourses.length > 0 && mycourses != null && mycourses != undefined ?
                                            <>
                                                {enrolledAsessments.length > 0 && (
                                                    <div>
                                                        <div className="row my-course-heading">

                                                            <h2><Translate textKey="mycourse012" /></h2>
                                                            <div className="col-lg-12">
                                                                <p class="m-0">
                                                                    <Translate textKey="mycourse013" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row pt-3">
                                                            {enrolledAsessments.map(course =>
                                                                <div class="col-lg-3 col-sm-12 col-xs-12 mb-3 ps-2 pe-2">
                                                                    <CourseCard courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
                                                                        courseName={course.courseName}
                                                                        duration={course.courseDuration}
                                                                        activeStage={course.activeStage}
                                                                        description={course.description}
                                                                        buttonClick={() => this.viewCourse(course)}
                                                                        sectorName={course.sectorName}
                                                                        buttonValue={course.isFormDetailsMandate == true && course.isCandidateFormSubmitted == false ? "mycourse008" : course.activeStage == 1 ? "TAKE TEST" : course.isCourseStarted == "False" ? "mycourse005" : course.isCourseStarted == "True" ? "mycourse006" : "VIEW COURSE"}
                                                                        type={(course.markedCompleted == "1" && course.activeStage === 1) ? "completed" : "enrolled"}
                                                                        externalExam={course.externalExam}
                                                                        egaPendingCnt={course.egaPendingCnt}
                                                                        batchID={course.batchId}
                                                                        batchStartDate={course.batchStartDate}
                                                                        batchEndDate={course.batchEndDate}
                                                                        vcStartTime={course.vcStartDate}
                                                                        vcDuration={course.vcDuration}
                                                                        isDisabled={() => this.isBtnDisabled('enrolled', course)}
                                                                        isBatch={course.activeStage == 4 && course.batchId === 0 ? false : true}
                                                                        isCandidateFormSubmitted={course.isCandidateFormSubmitted}
                                                                        isFormDetailsMandate={course.isFormDetailsMandate}
                                                                        courseCompletedOn={course.egaCompletionDate}
                                                                        proficiencyTestEndDate={course.proficiencyTestEndDate}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {complteAsessments.length > 0 && (
                                                    <div class="mt-3">
                                                        <div className="row my-course-heading">
                                                            {/*<h1>Completed<small className="text-muted-one">Courses</small></h1>*/}
                                                            <h2>Completed Courses</h2>
                                                            <div class="col-lg-12">
                                                                <p>
                                                                    Secure my career advancement by demonstrating my newly acquired knowlegde and expertise in my chosen professsion
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="container pb-7 p-0">
                                                            <div className="row completed-courses mb-5 ">
                                                                {complteAsessments.map((course) => {
                                                                    // console.log("course Item : ", course);
                                                                    return (
                                                                        <>
                                                                            <div className="col-lg-3 col-sm-12 col-xs-12 mb-3 ps-2 pe-2">
                                                                                <CourseCard courseData={course} courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
                                                                                    courseName={course.courseName} sectorName={course.sectorName} type="completed"
                                                                                    courseCompletedOn={course.egaCompletionDate}
                                                                                    activeStage={course.activeStage}
                                                                                    certificationDate={course.certificationDate}
                                                                                    isCertificateToShow={course.isCertificateToShow}
                                                                                    deliveryMode={course.deliveryMode} examMode={course.examMode} markForGrading={course.markForGrading} isPassed={course.isPassed}
                                                                                    certificate={course.certificate} buttonClick={!course.restrictCandidateAccess ? () => this.viewCourse(course) : () => this.downloadCandidateCertificate(course.courseName, course.certificate, course)}
                                                                                    restrictCandidateAccess={course.restrictCandidateAccess}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </> :
                                            <>
                                                <SkeletonCard />
                                            </>
                                    }
                                </div>
                            </>
                    }

                </div >
            )
        }
    }
}

// Mapping dispatch actions to props
/* const mapDispatchToProps = {
    callingDispose,
    updateFilterRetained,
    updateFilterVal,
    updateNodeVal,
    updateOnlineLec,
    updateIsUserLoggedIn,
    updateAccordianNo,
    updateggChild
  };
 */
const mapDispatchToProps = dispatch => ({
    callingDispose: value => dispatch(callingDispose(value)),
    updateFilterRetained: value => dispatch(updateFilterRetained(value)),
    updateFilterVal: value => dispatch(updateFilterVal(value)),
    updateNodeVal: value => dispatch(updateNodeVal(value)),
    updateOnlineLec: value => dispatch(updateOnlineLec(value)),
    updateIsUserLoggedIn: value => dispatch(updateIsUserLoggedIn(value)),
    updateAccordianNo: value => dispatch(updateAccordianNo(value)),
    updateggChild: value => dispatch(updateggChild(value)),
    updateParent: value => dispatch(updateParent(value)),
    updateChild: value => dispatch(updateChild(value)),
    updateActiveStage1List: value => dispatch(updateActiveStage1List(value)),
    updateActiveStage4List: value => dispatch(updateActiveStage4List(value)),
    updateIsActiveLi: value => dispatch(updateIsActiveLi(value)),
});

const mapStateToProps = (state) => {
    return {
        // Map Redux state to props
        isDeviceFromRedux: state.courseindex.isDevice,
        // Replace myReducer and myState with your actual reducer name and state name
        isActiveLi: state.navbar.isActiveLi
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(courselist);
