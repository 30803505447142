
export const HighestQualificationArray = [
  {
    text : "Highest Qualification",
    value : "first HQ",
    id : "1"
  },
  {
    text : "Highest Qualification",
    value : "Second HQ",
    id : "2"
  }
]

export const CurrentDesignation = [
  {
    text : "Current Designation",
    value : "Student CD",
    id : "1"
  },
  {
    text : "Current Designation",
    value : "teacher CD",
    id : "2"
  }
]

export const ClassesYouAreCurrentlyTeaching = [
  {
    text : "Classes you are currently teaching",
    value : "Class 1",
    id : "1"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 2",
    id : "2"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 3",
    id : "3"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 4",
    id : "4"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 5",
    id : "5"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 6",
    id : "6"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 7",
    id : "7"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 8",
    id : "8"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 9",
    id : "9"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 10",
    id : "10"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 11",
    id : "11"
  },
  {
    text : "Classes you are currently teaching",
    value : "Class 12",
    id : "12"
  },
]

export const SubjectsYouAreCurrentlyInstructing = [
  {
    text : "Subjects you are currently instructing",
    value : "Maths",
    id : "1"
  },
  {
    text : "Subjects you are currently instructing",
    value : "English",
    id : "2"
  },
  {
    text : "Subjects you are currently instructing",
    value : "Social Science",
    id : "3"
  },
  {
    text : "Subjects you are currently instructing",
    value : "Science",
    id : "4"
  },
]

export const Semester = [
  {
    text : "Semester",
    value : "Semester 1",
    id : "1"
  },
  {
    text : "Semester",
    value : "Semester 2",
    id : "2"
  }
]

export const OngoingPassedArray = [
  {
    text : "Ongoing/Passed",
    value : "Ongoing",
    id : true
  },
  {
    text : "Ongoing/Passed",
    value : "Passed",
    id : false
  }
]

export const CollegeNameArray = [
  {
    text : "College Name",
    value : "College Name 1",
    id : "1"
  },
  {
    text : "College Name",
    value : "College Name 2",
    id : "2"
  }
]

export const DepartmentArray = [
  {
    text : "Department",
    value : "BA",
    id : "1"
  },
  {
    text : "Department",
    value : "BCom",
    id : "2"
  },
  {
    text : "Department",
    value : "BSC",
    id : "3"
  },
  {
    text : "Department",
    value : "BBA",
    id : "4"
  },
  {
    text : "Department",
    value : "BCA",
    id : "5"
  },
  {
    text : "Department",
    value : "MCA",
    id : "6"
  },
  {
    text : "Department",
    value : "BCA",
    id : "7"
  },
]

export const organisationTabFields = [
  {
    name : "postregform015",
    id : 3,
  },
  {
    name : "postregform009",
    id : 3,
  }
]






