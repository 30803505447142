

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import Accordion from "../Shared/Accordion";
import { TimerFormat } from "../generalFunctions/timeFormat";
//import PopupOne from '../Shared/PopupOne';

export default function EligibilityCoursecard(props) {

    console.log("props in EligibilityCoursecard : ", props);

    return (
        <>
            <div class={`col-lg-3 col-sm-12 col-xs-12 course-indexCard  ${props.showHamburgerIcon ? "leftmenu-none display-desktop" : "display-mobile"}`}>

                <div class="card primary-radius card-modify">
                    <img src={props.courseIcon}/*"../../Assets/images/image1465.png "*/ class="card-img"></img>
                    <div class="card-body cardbody-height h-auto">
                        {/*<span class="course-type">{ props.sectorName}</span>*/}
                        <img src="../../Assets/Linqskills_Icons/More_24.svg" class="display-mobile float-end"></img>
                        <h4 class="line-clamp-two mt-2">{props.courseName}</h4>

                        <div class="duration m-0">
                            <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" />
                            <p class="mt-6px">{props.duration}</p>
                        </div>

                        <p class="line-clamp-three m-0 h7 mt-1  pb-3">
                            {props.description}
                        </p>

                    </div>
                    {/*   <div class="coursecard-list ps-3 pb-3">

                        <div class="d-flex pb-2 ps-2">
                            <img src="../../Assets/Linqskills_Icons/content-grey.svg" class="pe-2"></img>
                            <span>Content</span>
                        </div>
                        <div class="d-flex pt-2 pb-2 ps-2">
                            <img src="../../Assets/Linqskills_Icons/resource-grey.svg" class="pe-2"></img>
                            <span>Resource Library</span>
                        </div>
                        <div class="d-flex pt-2 pb-2 ps-2">
                            <img src="../../Assets/Linqskills_Icons/messages-disabled.svg" class="pe-2"></img>
                            <span>Messages</span>
                        </div>
                        <div class="d-flex pt-2 pb-2 ps-2 active-topic">
                            <img src="../../Assets/Linqskills_Icons/notes-active.svg" class="pe-2"></img>
                            <span>Notes</span>
                        </div>
                        <div class="d-flex pt-2 pb-2 ps-2">
                            <img src="../../Assets/Linqskills_Icons/bookmarks-disabled.svg" class="pe-2"></img>
                            <span>Bookmarks</span>
                        </div>
                        <div class="d-flex pt-2 pb-2 ps-2">
                            <img src="../../Assets/Linqskills_Icons/certificate-disabled.svg" class="pe-2"></img>
                            <span>Certificates</span>
                        </div>

                    </div> */}
                </div>


            </div>


            <div class="hamburger-menu display-mobile">
                {/*This is hidden till demo*/}
                {/*     <input id="menu__toggle" type="checkbox" />*/}
                {/*     <label class="menu__btn" for="menu__toggle">*/}
                {/*         <span></span>*/}
                {/*     </label>*/}

                <div class="col-lg-3 col-sm-12 col-xs-12 course-indexCard menu__box">
                    {/*<section class="modal overlay"></section>*/}
                    <div class="card primary-radius">
                        <img src="../../Assets/images/image1465.png"></img>
                        <div class="card-body cardbody-height h-auto">
                            <span class="course-type">Automotive</span>
                            <img src="../../Assets/Linqskills_Icons/More_24.svg" class="display-mobile float-end"></img>
                            <h4 class=" mt-2">Mastering the Art of Sales</h4>

                            <div class="duration m-0">
                                <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p class="mt-6px">12:00 Min</p>
                            </div>

                            <p class="line-clamp-three m-0 h7 mt-1">
                                This course includes: 6 Virtual Session, 4 Practical, 9 Self study, 3 Assessment, 2 Assignment, 3 Feedback session.
                            </p>

                        </div>
                        <div class="coursecard-list ps-3 pb-3">

                            <div class="d-flex pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/content-grey.svg" class="pe-2"></img>
                                <span>Content</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/resource-grey.svg" class="pe-2"></img>
                                <span>Resource Library</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/messages-disabled.svg" class="pe-2"></img>
                                <span>Messages</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2 active-topic">
                                <img src="../../Assets/Linqskills_Icons/notes-active.svg" class="pe-2"></img>
                                <span>Notes</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/bookmarks-disabled.svg" class="pe-2"></img>
                                <span>Bookmarks</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/certificate-diabled.svg" class="pe-2"></img>
                                <span>Certificates</span>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )

}


